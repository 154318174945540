import { FC, useCallback, useContext, useState, createContext, useEffect } from 'react';

import { isBrowser } from '@/utils/ssr';

import type { CognitoUserSession } from 'amazon-cognito-identity-js';

// TODO: [B] fix punk
export type AuthContextValue = {
  isAuthenticated: boolean;
  updateToken(token: string | null): void;
  session: any;
};

export const AUTH_TOKEN_KEY = 'authToken';
export const OPTIMISTIC_MODE = false;

const AuthContext = createContext<AuthContextValue>(null as TODO);

export const updateToken = (newToken?: string | null) => {
  if (!isBrowser) {
    return () => undefined;
  }

  if (!newToken) {
    console.log('=== CLEAR TOKEN');
    localStorage.removeItem(AUTH_TOKEN_KEY);
  } else {
    console.log('=== UPDATE TOKEN');
    localStorage.setItem(AUTH_TOKEN_KEY, newToken);
  }
};

export const useAuthContext = () => useContext(AuthContext);

// unused
export const AuthProvider: FC<AuxProps> = ({ children }) => {
  const [_token, setToken] = useState(
    (isBrowser && localStorage.getItem(AUTH_TOKEN_KEY)) ?? null
  );
  const [isAuthenticated, setIsAutheticated] = useState(OPTIMISTIC_MODE);

  const handleUpdateToken = useCallback(
    (newToken: string | null) => {
      setToken(newToken);
      updateToken(newToken);
      setIsAutheticated(newToken !== null);
    },
    [setToken]
  );

  useEffect(() => {
    (async () => {
      let session: CognitoUserSession | null = null;
      try {
        const { Auth } = await import(/* webpackChunkName: "lib_amplify" */ '@/lib/amplify');
        session = await Auth.currentSession();
      } catch (error) {
        if (error === `No current user`) {
          // TODO [C]: use internal Error object
          console.info(`Use is logged out`, error);
        } else {
          console.error(error);
          throw error;
        }
      }
      // TODO: [C] maybe redundant code
      handleUpdateToken(session?.getIdToken().getJwtToken() ?? null);
    })();
  }, [handleUpdateToken]);
  // TODO [C]: use usePromise

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        updateToken: handleUpdateToken,
        session: null,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
