import { FC, useEffect, useState } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import '@/config';
import theme from '@/theme';
import { AuthProvider } from '@/app/auth/AuthContext';

// import type { AuthContextValue } from '@/app/auth/AuthContext';

const queryClient = new QueryClient();

const useMocksServer = () => {
  const [isLoadingMocks, setIsLoadingMocks] = useState(
    !process.env.NEXT_PUBLIC_API_BASE_URL
  );

  useEffect(() => {
    (async () => {
      if (!process.env.NEXT_PUBLIC_API_BASE_URL) {
        const { mockServer } = await import('@/mocks/server');
        mockServer();
        setIsLoadingMocks(false);
      }
    })();
  }, []);

  return { isLoadingMocks };
};

type ProvidersProps = AuxProps & { session: any };

export const Providers: FC<ProvidersProps> = ({ children }) => {
  const { isLoadingMocks } = useMocksServer();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ChakraProvider
          theme={{
            ...theme,
          }}
        >
          {!isLoadingMocks && children}
        </ChakraProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};
