import Axios, { AxiosRequestConfig } from 'axios';

import { AUTH_TOKEN_KEY } from '@/app/auth/AuthContext';
import { isBrowser } from '@/utils/ssr';
import { updateToken } from '@/app/auth/AuthContext';

import type { CognitoUserSession } from 'amazon-cognito-identity-js';
import type { CognitoUser } from 'amazon-cognito-identity-js';
import type { AxiosResponse } from 'axios';

Axios.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const isExternal = !!config?.url?.startsWith('http');
    const token = isBrowser ? localStorage.getItem(AUTH_TOKEN_KEY) : null;

    const authHeaders =
      token && !isExternal ? { Authorization: `Bearer ${token}` } : {};
    return {
      baseURL: process.env.NEXT_PUBLIC_API_BASE_URL || '/api',
      ...config,
      headers: {
        ...authHeaders,
        ...config.headers,
      },
    };
  },
  (error) => Promise.reject(error)
);

Axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    const config = error?.config;
  // const response = error?.response;
  // TODO: check login URL
  if (error?.response?.status === 401 && error?.response?.data?.error === 'jwt_expired') {
    const { Auth } = await import(/* webpackChunkName: "lib_amplify" */ '@/lib/amplify');
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession: CognitoUserSession = await Auth.currentSession();

    return new Promise((resolve, reject) => {
      cognitoUser.refreshSession(currentSession.getRefreshToken(), (error: Error, session) => {
        if (error) {
          return reject(error);
        }
        const { idToken, refreshToken, accessToken } = session;
        updateToken(idToken.jwtToken);
        config.headers['Authorization'] = 'Bearer ' + idToken.jwtToken;
        console.info('refresh session:', idToken, refreshToken, accessToken);

        setTimeout(() => window.location.reload(), 50);

        resolve(Axios(config));
      });
    });
  } else {
    return Promise.reject(error);
  }
});

Axios.interceptors.response.use((response: AxiosResponse) => {
  if (response?.headers?.['x-total-count']) {
    return {
      content: response.data,
      totalItems: response.headers['x-total-count'],
    };
  }
  return response?.data;
});
