export const PATHNAMES = {
  home: '/',
  projects: '/project',
  projectDetail: '/project',
  app: '/app',
  dashboard: '/app/dashboard',
  login: '/app/login',
  publicLogout: '/auth/logout',
  publicLogin: '/auth/login',
  completeAccount: '/auth/complete',
  register: '/auth/register',
  confirm: '/auth/confirm',
  resetPassword: '/auth/reset-password',
  resetPasswordConfirm: '/auth/reset-password-confirm',
  news: '/news',
};

export const TOP_NAVIGATION = [
  { label: 'Home', path: '/' },
  // { label: 'Obchodní podmínky', path: '/page/lorem' },
  // { label: 'Kontaktujte nás', path: '/page/lorem' },
  { label: 'FAQ', path: '/page/faq' },
  { label: 'Registrace', path: PATHNAMES.register },
  { label: 'Katalog', path: PATHNAMES.projects },
];
