import Script from 'next/script';
import { Box, Text, useTheme } from '@chakra-ui/react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';

import { isBrowser } from '@/utils/ssr';
import { Providers } from '@/Providers';
import { Viewport } from '@/components/Viewport';
import { ErrorBoundary } from '@/errors';

import '@/theme/legacy.css';

const SENTRY_DNS = process.env.SENTRY_DNS || process.env.NEXT_PUBLIC_SENTRY_DNS;
const SENTRY_DISABLED = process.env.NEXT_PUBLIC_SENTRY_DISABLED === 'on';
const GA_CODE = process.env.GA_CODE || process.env.NEXT_PUBLIC_GA_CODE;
const GA_DISABLED = process.env.NEXT_PUBLIC_GA_DISABLED === 'on';

const AppDevHint = () => {
  const envName =
    process.env.NODE_ENV === 'development'
      ? 'Development'
      : process.env.NEXT_PUBLIC_DEV_ENV_NAME;
  const colorScheme =
    process.env.NODE_ENV === 'development'
      ? 'warning'
      : process.env.NEXT_PUBLIC_DEV_ENV_COLOR_SCHEME ?? 'success';

  if (!envName) {
    return null;
  }

  return (
    <Box
      zIndex="100"
      position="fixed"
      top="0"
      insetStart="0"
      insetEnd="0"
      h="2px"
      bg={`${colorScheme}.400`}
    >
      <Text
        position="fixed"
        top="0"
        insetStart="4"
        bg={`${colorScheme}.400`}
        color={`${colorScheme}.900`}
        fontSize="0.6rem"
        fontWeight="bold"
        px="1"
        borderBottomStartRadius="sm"
        borderBottomEndRadius="sm"
        textTransform="uppercase"
      >
        {envName}
      </Text>
    </Box>
  );
};

const AppHead = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Head>
      <title>{t<string>('meta:title')}</title>
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,viewport-fit=cover"
      />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <meta name="apple-mobile-web-app-capable" content="yes"></meta>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      {/* <link rel="manifest" href="/site.webmanifest" /> */}
      <link
        rel="mask-icon"
        href="/safari-pinned-tab.svg"
        color={theme.colors.gray?.['800']}
      />
      <meta
        name="msapplication-TileColor"
        content={theme.colors.gray?.['800']}
      />
      <meta name="theme-color" content={theme.colors.gray?.['800']} />
    </Head>
  );
};

const App = ({ Component, pageProps } : AppProps<{ session: any }>) => {
  const handleSentryOnLoad = () => {
    if (!isBrowser) {
      return;
    }
    window.Sentry?.onLoad(function() {
      window.Sentry?.init({
        dsn: SENTRY_DNS,
        tracesSampleRate: 1.0,
      });
    });
  };

  return (
    <Providers session={pageProps.session}>
      <AppHead />
      <ErrorBoundary>
        <Viewport>
          <Component {...pageProps} />
        </Viewport>
        <AppDevHint />
      </ErrorBoundary>
      {!SENTRY_DISABLED && (
        <Script
          onLoad={handleSentryOnLoad}
          strategy="lazyOnload"
          src={SENTRY_DNS}
        />
      )}
      {!GA_DISABLED && (<Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_CODE}`}
      />)}
      {!GA_DISABLED && (<Script
        id='google-analytics'
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-9PWRNBY8FG', {
          page_path: window.location.pathname,
          });
          `
        }}
      />)}
    </Providers>
  );
};

export default App;
