type Language = {
  key: string;
  fontScale?: number;
};

export const DEFAULT_LANGUAGE_KEY: Language['key'] = 'cs';

export const AVAILABLE_LANGUAGES: Language[] = [
  {
    key: 'cs',
  },
  {
    key: 'en',
  },

  // {
  //   key: 'ar',
  //   dir: 'rtl',
  //   fontScale: 1.2,
  // },
];
